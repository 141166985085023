/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable dot-notation */

// libraries
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { UIDReset, UIDConsumer } from 'react-uid';
import {
    object, string, arrayOf, shape, bool,
} from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import QueryString from 'qs';
import mbpLogger from 'mbp-logger';

// ui components
import BrandTabs from './BrandTabs';
import AboveTabs from './AboveTabs';
import ConnectedSearch from './Search';
import SearchNavIcon from './SearchNavIcon';
import ConnectedCart from './Cart'; // importing Cart as ConnectedCart to address linter error
import TopMenu from './TopMenu/TopMenu';
import ConnectedTrackOrders from './TrackOrders';
import ConnectedSignIn from './SignIn/SignIn';
import EyebrowBanner from './EyebrowBanner';
import Favorites from './Favorites';

// gql, redux, helpers
import extractMenuAttributes from '../../../helpers/contentstack/extractMenuAttributes';
import isProductListingAd from '../../../helpers/isProductListingAd';
import { getUserRole } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getAddressSource, getDataDictionary } from '../../../../state/ducks/App/App-Selectors';
import { selectors } from '../../../../state/ducks/App';
import { determineLanguagePrefixPath } from '../../../helpers/determineInternationalizationPath';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = (theme) => ({
    LogoRowContainer1: {
        display: 'block',
        width: '100%',
        left: '0',
        zIndex: '1080',
        position: 'fixed',
        top: '35px',
    },
    LogoRowContainerOtc1: {
        display: 'block',
        width: '100%',
        left: '0',
        zIndex: '1080',
        position: 'fixed',
        top: '0',
    },
    NavContainer: {
        position: 'relative',
        display: 'flex',
        height: '105px',
        width: '100%',
        alignItems: 'center',
        fontFamily: 'Hind, sans-serif',
        // '@media screen and (max-width: 1430px)': {
        //     padding: '0 15px 0 12px',
        // },
        '@media screen and (min-width: 768px)': {
            flexFlow: 'row nowrap',
            justifyContent: 'flex-start',
        },
        padding: '0.5rem 1rem',
        background: `${theme.palette.bgHeader}ed`,
    },
    LogoRowContainer2: {
        '@media screen and (min-width: 768px)': {
            flexWrap: 'nowrap',
            maxWidth: '1025px',
        },
        '@media (min-width: 1264px)': {
            maxWidth: '1270px',
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 auto',
        padding: '0',
        width: '100%',
    },
    LeftContainer: {
        '& a': {
            '& img': {
                height: '41px',
            },
        },
    },
    LogoRowContainer3: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    LogoNavMenuContainer: {
        width: '700px',
    },
    selected: {
        backgroundColor: `${theme.palette.primary?.main} !important`,
        color: theme.palette.white,
        '& a': {
            color: `${theme.palette.white} !important`,
        },
    },
    LogoImage: {
        paddingTop: '5px',
        width: '150px',
    },
    LinkIcon: {
        marginTop: '2px',
        display: 'block',
        textDecoration: 'none',
        color: '#000',
        textAlign: 'center',
        fontSize: '11px',
        paddingRight: '5px',
    },
    LinkIconContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
    },
    plaBanner: {
        maxWidth: '400px',
        position: 'absolute',
        top: 0,
        right: '50%',
        left: '50%',
        width: '100%',
        display: 'flex',
        transform: 'translate(-50%, 0%)',
    },
    ArrowDropDown: {
        color: theme.palette.black,
        fontSize: '28px !important',
        marginLeft: '-8px',
    },
    linkContainer: {
        padding: '5px 0',
    },
    linkContainer2: {
        display: 'flex',
        paddingRight: 0,
        paddingTop: '15px',
        paddingBottom: '0.5rem',
    },
    plantsHeaderHide: {
        top: '-200px !important',
        '-webkit-transition': '0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67)',
    },
});

const LINKSTYLE_NORMAL = 'Normal';
// const LINKSTYLE_DIVIDER = 'Divider';
const isMenuMissingOrEmpty = (blockObj, menuName) => {
    if (!blockObj.menu_ref || !Array.isArray(blockObj.menu_ref) || blockObj.menu_ref.length === 0
        || !blockObj.menu_ref[0].menu_blocks || !Array.isArray(blockObj.menu_ref[0].menu_blocks) || blockObj.menu_ref[0].menu_blocks.length === 0) {
        mbpLogger.logError({
            message: `Missing or empty ${menuName} menu in DesktopHeaderBlock`,
            block: blockObj,
        });
        return true;
    }

    return false;
};

const isMenuMissingOrEmpty2 = (blockObj, menuName) => {
    if (isMenuMissingOrEmpty(blockObj, menuName)
        || !blockObj.menu_ref[0].menu_blocks[0].menu_links
        || !blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link
        || !Array.isArray(blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link)
        || blockObj.menu_ref[0].menu_blocks[0].menu_links.menu_link.length === 0) {
        mbpLogger.logError({
            message: `block not in expected format in ${menuName} caller`,
            block: blockObj,
        });
        return true;
    }

    return false;
};

const isBrandTabsMissingOrEmpty = (brandTabs, menuName) => {
    if (
        !brandTabs
        || !Array.isArray(brandTabs)
        || brandTabs.length === 0) {
        mbpLogger.logError({
            message: `block not in expected format in ${menuName} caller`,
            block: brandTabs,
        });
        return true;
    }

    return false;
};

const callAboveTabs = (blockObj) => {
    if (isMenuMissingOrEmpty2(blockObj, 'Above Tabs')) {
        return null;
    }

    const menu = blockObj.menu_ref[0];

    // This is a mini version of MenuBuilder
    const navMenu = [];
    menu.menu_blocks[0].menu_links.menu_link.forEach((item) => {
        const navItem = {
            label: item.link && item.link.title ? item.link.title : '', // eslint-disable-line key-spacing
            link: item.link && item.link.href ? item.link.href : '', // eslint-disable-line key-spacing
        };
        item.icon && (navItem.icon = item.icon);             // eslint-disable-line no-unused-expressions
        item.font_color && (navItem.color = item.font_color);       // eslint-disable-line no-unused-expressions
        item.background_color && (navItem.backgroundColor = item.background_color); // eslint-disable-line no-unused-expressions
        item.placement && (navItem.placement = item.placement); // eslint-disable-line no-unused-expressions
        navMenu.push(navItem);
    });

    return <AboveTabs menu={navMenu} attributes={extractMenuAttributes(menu)} />;
};

const callBrandTabs = (brand, pathname, brandTabs, addressSource) => {
    if (isBrandTabsMissingOrEmpty(brandTabs, 'Brand Tabs')) {
        return null;
    }
    const pageUrl = ['/tmobile-tuesdays', '/verizon-up-400203990', '/flowers-gifts-400209995'];
    // This is a mini version of MenuBuilder
    const navMenu = [];
    brandTabs.forEach((item) => {
        const linkStyle = item.link_style || LINKSTYLE_NORMAL;
        switch (linkStyle) {
            case LINKSTYLE_NORMAL:
                if (addressSource === 'SCI') {
                    navMenu.push({
                        label: item?.link?.title ? item.link.title : '',   // brand code (e.g. HD)
                        link: item?.link?.href ? item.link.href : '',
                        icon: item?.icon || '',      // brand logo
                        fullBrandName: item?.full_brand_name || '',
                        catalogId: item?.catalog_id || '',
                        storeId: item?.store_id || '',
                        domain: item?.domain_name || '',
                        linkStyle: LINKSTYLE_NORMAL,
                    });
                } else {
                    navMenu.push({
                        label: item?.url?.title ? item.url.title : '',   // brand code (e.g. HD)
                        link: item?.url?.href ? item.url.href : '',
                        icon: item?.icon || '',      // brand logo
                        fullBrandName: item?.full_brand_name || '',
                        catalogId: item?.catalog_id || '',
                        storeId: item?.store_id || '',
                        domain: item?.domain_name || '',
                        linkStyle: LINKSTYLE_NORMAL,
                    });
                }
                break;
            default:
                mbpLogger.logWarning({
                    message: 'Do not know how to handle this kind of menu item in Brand Tabs',
                    item,
                    block: brandTabs,
                });
        }
    });

    if (pageUrl.indexOf(pathname) !== -1) {
        return false;
    }
    return <BrandTabs menu={navMenu} currentBrand={brand} />;
};

// Logo (main) row methods

const findMenuBlockObj = (menuBlocks, blockName) => {
    const results = menuBlocks.filter((menuBlock) => (Object.keys(menuBlock)[0]) === blockName);
    return results.length ? results[0][blockName] : null;
};

const findRenderBrandLogo = (menuBlocks, classes) => {
    const menuBlockObj = findMenuBlockObj(menuBlocks, 'logo');
    if (menuBlockObj && menuBlockObj.image && menuBlockObj.image.url) {
        let description = '';
        if (menuBlockObj.link?.title) {
            description = menuBlockObj.link?.title;
        } else {
            description = 'logo';
        }
        return (
            <Link to={determineLanguagePrefixPath('/')} className={classes.LogoImage} aria-label="brand-link" aria-labelledby="brand-link">
                <img
                    src={menuBlockObj.image.url}
                    // onError={e => addDefaultSrc(e)}    // TODO?
                    alt={description}
                    className={classes.LogoImage}
                />
            </Link>
        );
    }

    return null;
};

// The "main" row of the header (aka the Logo row) is a bit of a strange case.
// We can't quite follow the scheme of "just render everything the same in the order it appears in the block"
// because the scheme is:  Logo     search-box      everything-else-together-in-one-group
// so that's the scheme the code follows.
const renderLogoRow = (blockObj, brand, classes, location, featureFlags, addressSource, userRole, showSearch, history, dataDictionary, headerShow, isConfirmOrder) => {
    if (isMenuMissingOrEmpty(blockObj, 'Logo Search Widgets')) {
        return null;
    }
    const isPlaEnabled = featureFlags['is-pla-flow-enabled'];
    const urlParams = QueryString.parse(location.search);
    const bannerCode = urlParams?.r || urlParams?.['?r'] || null;
    /**
     * determineSeoBanner - determines banner to show based on bannercode
     * @param {string} bCode banner code
     * @returns {string} img url
     */
    const determineSeoBanner = (bCode) => {
        if (bCode?.includes('google')) {
            return '//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt5fdc02cf0e2820a5/5e9b683bb170456000419312/pla_banner_desktop.jpg';
        }
        if (bCode?.includes('bing')) {
            return '//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt9c9ba91ea5f8a85c/5f4f9176891266279cd6e244/pla_banner_desktop_bing.jpg';
        }
        return null;
    };

    const logoRowContainerStyle = isConfirmOrder ? classes.LogoRowContainerOtc1 :  classes.LogoRowContainer1;
    const enableToConfirmationPage = false;

    return (
        <section>
            <div id="navbar" className={`${logoRowContainerStyle} ${!headerShow ? classes.plantsHeaderHide : null}`}>
                <nav className={classes.NavContainer}>
                    <div className={classes.LogoRowContainer2}>
                        <div className={classes.LogoRowContainer3}>
                            {findRenderBrandLogo(blockObj.menu_ref?.[0]?.menu_blocks, classes)}
                            <div className={classes.LogoNavMenuContainer} />
                            <div className={classes.LinkIconContainer}>
                                <UIDReset>
                                    <UIDConsumer>
                                        {(id, uidKey) => blockObj.menu_ref?.[0]?.menu_blocks.map(
                                            (block) => <LogoRowBlock block={block} brand={brand} key={uidKey(block)} classes={classes} featureFlags={featureFlags} userRole={userRole} history={history} dataDictionary={dataDictionary} enableToConfirmationPage={enableToConfirmationPage} />,
                                        )}
                                    </UIDConsumer>
                                </UIDReset>
                            </div>
                        </div>
                        {isPlaEnabled && isProductListingAd(location.search) && bannerCode?.includes('pla') ? (
                            [
                                (determineSeoBanner(bannerCode)
                                    ? (
                                        <img
                                            className={classes.plaBanner}
                                            src={determineSeoBanner(bannerCode)}
                                            alt="Welcome Customers"
                                            aria-hidden
                                        />
                                    )
                                    : null
                                ),
                            ]
                        ) : [
                            (addressSource !== 'SCI' && showSearch === 'yes'
                                ? <ConnectedSearch brand={brand} typeSearchBox="desktop" />
                                : null
                            ),
                        ]}
                    </div>
                </nav>
            </div>
        </section>
    );
};

const LogoRowBlock = ({
    block, brand, classes, featureFlags, userRole, enableToConfirmationPage,
}) => {
    const menuBlockKey = Object.keys(block)[0];
    const menuBlockObj = block[menuBlockKey];

    switch (menuBlockKey) {
        case 'logo':
            break;  // already rendered this

        case 'widgets':
            switch (menuBlockObj.type) {
                case '[SEARCH]':
                    break;  // already rendered this

                case '[SHOPPING_CART]':
                    break;
                    // return <Cart />;

                default:
                    mbpLogger.logError({
                        message: `Unknown menu-block widget type ${menuBlockObj.type} in DesktopPlantsHeaderBlock`,
                        brand,
                        block,
                    });
                    return null;
            }
            break;

        case 'menu_groups':
            break;

        case 'menu_links':
            if (menuBlockObj.menu_link && Array.isArray(menuBlockObj.menu_link) && menuBlockObj.menu_link.length) {
                return (
                    <UIDReset>
                        <UIDConsumer>
                            {(id, uidKey) => menuBlockObj.menu_link.map(
                                (menuLinkObj) => (
                                    <div className={classes.linkContainer}>
                                        <div className={classes.linkContainer2}>
                                            <LogoRowBlockLink obj={menuLinkObj} brand={brand} key={uidKey(menuLinkObj)} classes={classes} featureFlags={featureFlags} userRole={userRole} enableToConfirmationPage={enableToConfirmationPage} />
                                        </div>
                                    </div>
                                ),
                            )}
                        </UIDConsumer>
                    </UIDReset>
                );
            }
            return null;

        default:
            mbpLogger.logError({
                message: `Unknown menu-block type ${menuBlockKey} in DesktopPlantsHeaderBlock`,
                brand,
                block: menuBlockObj,
            });
            return null;
    }

    return null;
};

LogoRowBlock.propTypes = {
    classes: object.isRequired,
    block: shape({
        logo: shape({
            image: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string.isRequired,
                uid: string,
                url: string.isRequired,
            }),
            link: shape({
                href: string.isRequired,
                title: string.isRequired,
            }),
        }),
        menu_links: shape({
            menu_link: arrayOf(shape({
                icon: string,
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }),
            })),
        }),
        menu_blocks: shape({
            title: string.isRequired,
            menu_blocks: arrayOf(shape({
                menu_groups: shape({
                    title: string.isRequired,
                    menu_item: arrayOf(shape({
                        icon: shape({
                            content_type: string,
                            file_size: string,
                            filename: string,
                            is_dir: bool,
                            title: string.isRequired,
                            uid: string,
                            url: string.isRequired,
                        }).isRequired,
                        link: shape({
                            href: string.isRequired,
                            title: string.isRequired,
                        }).isRequired,
                    })),
                }),
            })).isRequired,
            menu_item: arrayOf(shape({
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }),
            })).isRequired,
        }),
    }).isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    featureFlags: shape({
        'is-pla-flow-enabled': bool,
        'is-signin-enabled': bool,
        'is-only-usd-currency-allowed': bool,
    }).isRequired,
    userRole: string.isRequired,
    enableToConfirmationPage: bool.isRequired,
};

const LogoRowBlockLink = ({
    obj, brand, featureFlags, userRole, enableToConfirmationPage,
}) => {
    const dispatch = useDispatch();
    const handleTrackEvents = (eventParams) => dispatch(trackEvent(eventParams));
    const title = obj && obj.link && obj.link.title ? obj.link.title : null;
    const href = obj && obj.link && obj.link.href ? obj.link.href : '/';
    const isSigninEnabled = featureFlags['is-signin-enabled'];
    const icon = (userRole === 'P' && obj.passport_user_icon) ? obj.passport_user_icon : obj.icon;
    if (enableToConfirmationPage && title !== 'Shopping Cart') {
        return null;
    }
    switch (title) {
        case 'Search':
            return <SearchNavIcon image={icon} />;

        case 'Favorite':
            return <Favorites image={icon} trackEvent={handleTrackEvents} />;

        case 'Sign In':
            return (isSigninEnabled) ? <ConnectedSignIn image={icon} trackEvent={handleTrackEvents} /> : null;

        case 'My Orders':
            return (isSigninEnabled) ? <ConnectedTrackOrders languageId={1} brand={brand} image={obj.icon || ''} trackEvent={handleTrackEvents} /> : null;

        case 'Shopping Cart':
            return <ConnectedCart image={obj.icon || ''} brand={brand} trackEvent={handleTrackEvents} />;

        case 'Notifications':
            return (
                <Link to={href}>
                    <img src={obj.icon} alt={title} />
                </Link>
            );
        default:
            mbpLogger.logError({
                message: `Unknown menu-block-link ${title} or unexpected structure in DesktopPlantsHeaderBlock`,
                brand,
                obj,
            });
            return null;
    }
};

LogoRowBlockLink.propTypes = {
    obj: shape({
        icon: string.isRequired,
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }),
    }).isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    featureFlags: shape({
        'is-pla-flow-enabled': bool,
        'is-signin-enabled': bool,
    }).isRequired,
    userRole: string.isRequired,
    enableToConfirmationPage: bool.isRequired,
};

const getRenderTab = (blockObj, isPlaEnabled, location, addressSource, brand, pathname, brandTabs, classes, featureFlags, userRole, showSearch, history, dataDictionary, headerShow, countryName, presentation_family, isConfirmOrder) => {
    switch (blockObj.name) {
        case 'Above Tabs':
            if (isPlaEnabled && isProductListingAd(location.search) && addressSource !== 'SCI') {
                return null;
            }
            return callAboveTabs(blockObj);

        case 'Brand Tabs':
            if (isPlaEnabled && isProductListingAd(location.search)) {
                return null;
            }
            return callBrandTabs(brand, pathname, brandTabs, addressSource);

        case 'Logo Search Widgets':
            return renderLogoRow(blockObj, brand, classes, location, featureFlags, addressSource, userRole, showSearch, history, dataDictionary, headerShow, isConfirmOrder);

        case 'Top Nav':
            if (isMenuMissingOrEmpty(blockObj, 'TopNav')) {
                return null;
            } // Erase the plants conditional after the component is built)
            if ((countryName !== '' && countryName !== 'united states' && countryName !== 'canada') && presentation_family !== 'food') {
                return null;
            }
            if ((presentation_family === 'plants')) {
                return (
                    <TopMenu
                        menu={blockObj.menu_ref[0].menu_blocks}
                        brand={brand}
                        attributes={blockObj.menu_ref[0]}
                        ariaLabel="Main Navigation"
                        headerShow={headerShow}
                        isConfirmOrder={isConfirmOrder}
                    />
                );
            }
            return null;

        case 'Top Nav 2':
            if (isMenuMissingOrEmpty(blockObj, 'TopNav')) {
                return null;
            }
            return (
                <TopMenu
                    menu={blockObj.menu_ref[0].menu_blocks}
                    brand={brand}
                    attributes={blockObj.menu_ref[0]}
                    ariaLabel="Secondary Navigation"
                    headerShow={headerShow}
                    isConfirmOrder={isConfirmOrder}
                />
            );
        case 'Eyebrow Banner':
            return null;
        default:
            mbpLogger.logError({
                message: `Unknown header block type "${blockObj.name}" in DesktopPlantsHeaderBlock`,
                brand,
                block: blockObj,
            });
            return null;
    }
};
// Each block returned from ContentStack is a row of the desktop header.
// This component is called by DesktopGraphqlHeader to render one block (one row) of the desktop header.
const DesktopPlantsHeaderBlock = ({
    block, brand, classes, location, featureFlags, addressSource, brandTabs, countryName, presentation_family, userRole, showSearch, history, dataDictionary, eyebrowVisible,
}) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    const isPlaEnabled = featureFlags['is-pla-flow-enabled'];
    const { pathname } = location;
    const isConfirmOrder = location?.pathname?.includes('/checkout/order-confirmation');
    const [headerShow, setHeaderShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            // if scroll down hide the navbar
            if (Math.max.apply(null, [lastScrollY, window.scrollY]) > 45) {
                if (window.scrollY > lastScrollY) setHeaderShow(false);
                else setHeaderShow(true);  // if scroll up show the navbar
            }
            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        // cleanup function
        return () => window.removeEventListener('scroll', controlNavbar);
    }, [lastScrollY]);
    if (eyebrowVisible && block?.eyebrow_banner && !location?.pathname?.includes('/checkout/order-confirmation')) {
        return <EyebrowBanner brand={brand} eyebrowBanner={block.eyebrow_banner} />;
    }
    if (location?.pathname?.includes('/checkout/') && !location?.pathname?.includes('/checkout/order-confirmation/')) {
        return '';
    }
    if (!blockObj.name) {
        mbpLogger.logError({
            message: 'Unknown header block type (or missing menu name) in DesktopPlantsHeaderBlock',
            brand,
            block: blockObj,
        });
        return null;
    }

    return getRenderTab(blockObj, isPlaEnabled, location, addressSource, brand, pathname, brandTabs, classes, featureFlags, userRole, showSearch, history, dataDictionary, headerShow, countryName, presentation_family, isConfirmOrder);
};

DesktopPlantsHeaderBlock.propTypes = {
    dataDictionary: shape({
        Language: string.isRequired,
        Currency: string.isRequired,
    }),
    block: shape({
        eyebrow_banner: shape({
            eyebrow_background_color: string,
            eyebrow_color: string,
            eyebrow_link_title: string,
            eyebrow_link_url: string,
            eyebrow_text: string,
        }),
        menu: shape({
            name: string.isRequired,
            menu_ref: arrayOf(shape({
                menu_blocks: arrayOf(shape({
                    logo: shape({
                        image: shape({
                            content_type: string,
                            file_size: string,
                            filename: string,
                            is_dir: bool,
                            title: string.isRequired,
                            uid: string,
                            url: string.isRequired,
                        }),
                        link: shape({
                            href: string.isRequired,
                            title: string.isRequired,
                        }),
                    }),
                    menu_links: shape({
                        menu_link: arrayOf(shape({
                            icon: string,
                            link: shape({
                                href: string.isRequired,
                                title: string.isRequired,
                            }),
                        })),
                    }),
                    menu_blocks: shape({
                        title: string.isRequired,
                        menu_blocks: arrayOf(shape({
                            menu_groups: shape({
                                title: string.isRequired,
                                menu_item: arrayOf(shape({
                                    icon: shape({
                                        content_type: string,
                                        file_size: string,
                                        filename: string,
                                        is_dir: bool,
                                        title: string.isRequired,
                                        uid: string,
                                        url: string.isRequired,
                                    }).isRequired,
                                    link: shape({
                                        href: string.isRequired,
                                        title: string.isRequired,
                                    }).isRequired,
                                })),
                            }),
                        })).isRequired,
                        menu_item: arrayOf(shape({
                            link: shape({
                                href: string.isRequired,
                                title: string.isRequired,
                            }),
                        })).isRequired,
                    }),
                })).isRequired,
            })).isRequired,
        }),
    }).isRequired,
    classes: object.isRequired,
    brand: shape({
        domain: string.isRequired,
    }).isRequired,
    location: shape({
        pathname: string.isRequired,
        search: string.isRequired,
    }).isRequired,
    featureFlags: shape({
        'is-pla-flow-enabled': bool,
        'is-signin-enabled': bool,
    }).isRequired,
    addressSource: string,
    userRole: string.isRequired,
    brandTabs: arrayOf(shape({
        brand_id: string.isRequired,
        catalog_id: string.isRequired,
        domain_name: string.isRequired,
        full_brand_name: string.isRequired,
        icon: string.isRequired,
        store_id: string.isRequired,
        url: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
    })).isRequired,
    countryName: string,
    presentation_family: string.isRequired,
    showSearch: string.isRequired,
    history: object.isRequired,
    eyebrowVisible: bool,
};

DesktopPlantsHeaderBlock.defaultProps = {
    addressSource: '',
    countryName: '',
    dataDictionary: {},
    eyebrowVisible: true,
};

const mapStateToProps = (state) => ({
    addressSource: getAddressSource(state),
    countryName: selectors.getAppCountry(state),
    presentation_family: getPresentationFamily(state),
    userRole: getUserRole(state),
    dataDictionary: getDataDictionary(state),
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(DesktopPlantsHeaderBlock)));
